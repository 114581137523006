import React, { useState } from 'react';
import styled from 'styled-components';

import { TagsMap, TagsArray } from '@t/components';
import { IconType } from '@t/styles';
import { POST_ALL_TAG, postUrl } from '@/constant';
import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';
import { Icon } from '@/components/common/Icon';

interface PostTagsProps {
  isShowCnt?: boolean;
  currentTag?: string;
  tags: TagsMap | TagsArray;
  lang: 'ko' | 'en';
}

interface TagWithCountProps {
  currentTag?: string;
  tags: TagsMap;
  lang: 'ko' | 'en';
}

interface TagProps {
  tags: TagsArray;
  lang: 'ko' | 'en';
}

const Tags = styled.div`
  & a {
    margin-right: 10px;
  }

  &.showCnt a {
    margin-top: 12px;
  }

  @media (max-width: 720px) {
    &.more {
      max-height: 105px;
      overflow: hidden;
    }

    &.open {
      max-height: none;
    }

    & a {
      margin-top: 5px;
    }

    &.showCnt a {
      margin-top: 10px;
    }
  }
`;

const Tag = styled.div`
  height: 28px;
  line-height: 27px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 14px;
  color: #222;

  &:hover {
    border: 1px solid #b0b6ff;
    background-color: #edeeff;
  }

  &.selected {
    background-color: #515ce6;
    border: none;

    & span {
      color: #fff;
    }
  }

  @media (max-width: 720px) {
    height: auto;
    line-height: 2;
    font-size: 11px;
    border-radius: 4px;
    padding: 0 8px;
  }
`;

const TagName = styled.span`
  color: #222;
  margin-right: 4px;
`;

const TagCount = styled.span`
  color: #aaa;
`;

const MobileMoreButtonWrapper = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
    font-size: 14px;
    color: #515ce6;
    font-weight: 500;
    cursor: pointer;
  }
`;

const MoreIcon = styled(Icon)`
  @media (max-width: 720px) {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    background-size: 1200px;
  }
`;

const getTagsWithCntComponent = ({ currentTag, tags, lang }: TagWithCountProps) => {
  const prefix = postUrl[lang.toUpperCase() as 'KO' | 'EN'];
  const ga = createGA('Post - main', 'click', 'post tag');

  return Object.keys(tags).map((tag) => {
    const url = tag === POST_ALL_TAG ? `${prefix}` : `${prefix}/${tag}`;
    const selected = currentTag === tag || (!currentTag && tag === POST_ALL_TAG) ? 'selected' : '';

    return (
      <InlineBlockLink
        key={tag}
        type="internal"
        url={url}
        testId="tags"
        ga={{ ...ga, label: `post tag - ${tag}` }}
      >
        <Tag className={selected}>
          <TagName>{`#${tag}`}</TagName>
          <TagCount>{tags[tag]}</TagCount>
        </Tag>
      </InlineBlockLink>
    );
  });
};

const getTagsComponent = ({ tags, lang }: TagProps) => {
  const prefix = `${postUrl[lang.toUpperCase() as 'KO' | 'EN']}`;
  const ga = createGA('Post - main', 'click', 'post tag');

  return tags.map((tag) => (
    <InlineBlockLink
      key={tag}
      type="internal"
      url={`${prefix}/${tag}`}
      testId="tags"
      ga={{ ...ga, label: `post tag - ${tag}` }}
    >
      <Tag className="tag">
        <TagName>{`#${tag}`}</TagName>
      </Tag>
    </InlineBlockLink>
  ));
};

const PostTags: React.FC<PostTagsProps> = ({ currentTag, tags, lang, isShowCnt }) => {
  const [isMobileMore, setMobileMore] = useState<'more' | 'less'>(!currentTag ? 'more' : 'less');
  const cntClassName = isShowCnt ? 'showCnt' : '';

  return (
    <>
      <Tags className={`${cntClassName} ${isMobileMore}`}>
        {isShowCnt
          ? getTagsWithCntComponent({ currentTag, tags, lang } as TagWithCountProps)
          : getTagsComponent({ lang, tags } as TagProps)}
      </Tags>
      {isShowCnt ? (
        <MobileMoreButtonWrapper
          onClick={() => setMobileMore(isMobileMore === 'more' ? 'less' : 'more')}
        >
          {isMobileMore}
          <MoreIcon iconType={`POST_TAG_${isMobileMore.toUpperCase()}_ICON` as IconType} />
        </MobileMoreButtonWrapper>
      ) : null}
    </>
  );
};

export default PostTags;
