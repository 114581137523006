import React from 'react';
import styled from 'styled-components';

import { PostNode } from '@t/components';
import { postUrl } from '@/constant';
import PostThumbnail from '@/components/post/PostThumbnail';
import PostTags from '@/components/post/PostTags';
import { BlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

import { getReadingTime } from '@/util';

interface PostListItemProps {
  post: PostNode;
  lang: 'ko' | 'en';
}

const PostItem = styled.li`
  padding: 40px 0;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    width: 89%;
    margin: 0 auto;
    padding: 20px 0;
  }
`;

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;

  & > a {
    position: relative;
    width: 25%;
    height: 0;
    padding-bottom: 16.4%;
    max-width: 240px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 5%;

  @media (max-width: 720px) {
    max-width: 480px;

    & .tag {
      font-size: 11px;
    }
  }
`;

const PostInfo = styled.div`
  margin-bottom: 15px;

  &:hover h3 {
    color: #515ce6;
  }

  @media (max-width: 720px) {
    margin: 0;
  }
`;

const Title = styled.h3`
  font-size: 26px;
  line-height: 32px;
  color: #222;
  margin: 0;
  margin-bottom: 15px;
  max-width: 780px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 720px) {
    max-width: 480px;
    white-space: normal;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }
`;

const Description = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  color: #222;
  max-height: 52px;

  @media (max-width: 720px) {
    display: none;
  }
`;

const Date = styled.span`
  font-size: 14px;
  color: #777;
  margin-top: 30px;

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

let cnt = 0;

const getDefaultThumbnail = () => {
  cnt += 1;
  cnt = cnt > 3 ? 1 : cnt;

  return require(`../../assets/images/thumbnail/default_${cnt}.png`);
};

const PostListItem: React.FC<PostListItemProps> = ({ post, lang }) => {
  const { id, title, description, date, tags, thumbnail } = post.node.frontmatter;
  const { rawMarkdownBody } = post.node;

  const url = `${postUrl.PREFIX}/${id}`;
  const ga = createGA('Posts - main', 'click', 'post', title);
  const defaultThumbnail = thumbnail || getDefaultThumbnail();

  return (
    <PostItem data-testid="post-list">
      <ItemContent>
        <InfoWrapper>
          <PostInfo>
            <BlockLink type="internal" url={url} ga={ga}>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </BlockLink>
          </PostInfo>
          <PostTags lang={lang} tags={tags} />
          <Date>
            {date} - {getReadingTime(rawMarkdownBody, lang)}
          </Date>
        </InfoWrapper>
        <PostThumbnail thumbnail={defaultThumbnail} url={url} />
      </ItemContent>
    </PostItem>
  );
};

export default PostListItem;
