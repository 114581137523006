import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface LazyImageProps {
  src: string;
}

const SkeletonImage = styled.span`
  position: absolute;
  opacity: 0.3;
  background-color: #8c8c8c;
  border-radius: 2px;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  max-height: 100%;
`;

const LazyImage: React.FC<LazyImageProps> = ({ src }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const onLoad = () => setLoaded(true);

  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoad();
    }
  }, [imgRef]);

  return (
    <>
      <Image
        ref={imgRef}
        src={src}
        onLoad={onLoad}
        style={{ display: `${isLoaded ? '' : 'none'}` }}
      />
      {!isLoaded && <SkeletonImage />}
    </>
  );
};

export default LazyImage;
