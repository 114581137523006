import React from 'react';
import styled from 'styled-components';

import { PostNode } from '@t/components';
import PostListItem from '@/components/post/PostListItem';
import { List } from '@/components/common/List';

interface PostListProps {
  posts: {
    edges: PostNode[];
  };
  lang: 'ko' | 'en';
}

const Wrapper = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    padding: 0;
  }
`;

const PostList = styled(List)`
  padding: 20px 0 60px;

  @media (max-width: 720px) {
    padding: 0;
  }
`;

const PostsList: React.FC<PostListProps> = ({ posts, lang }) => (
  <Wrapper>
    <PostList>
      {posts.edges.map((post) => (
        <PostListItem key={post.node.frontmatter.id} post={post} lang={lang} />
      ))}
    </PostList>
  </Wrapper>
);

export default PostsList;
