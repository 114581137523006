import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { ProductInfo, PostNode, TagsMap } from '@t/components';
import { createProductSectionData, getSuffix } from '@/util';
import { postUrl } from '@/constant';
import SEO from '@/components/SEO';
import PostTags from '@/components/post/PostTags';
import PostsList from '@/components/post/PostsList';
import PostsPagination from '@/components/post/PostsPagination';
import SitemapSection from '@/components/main/sitemap/SitemapSection';
import createGA from '@/components/common/createGA';

import '@/query/postQueryFragment';

interface PostIndexProps {
  pageContext: {
    lang: 'ko' | 'en';
    tag?: string;
    tags: TagsMap;
    visiblePages: number[];
    previous: number | null;
    next: number | null;
    numPages: number;
    skipPrevVisible: number;
    skipNextVisible: number;
  };
  location: {
    pathname: '/posts/ko' | '/posts/en';
  };
  data: {
    allPosts: Posts;
    posts: Posts;
    allEnPosts: Posts;
    enPosts: Posts;
    allProductsJson: { edges: { node: ProductInfo }[] };
  };
}

interface Posts {
  edges: PostNode[];
}

const Contents = styled.div`
  margin-top: 69px;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 720px) {
    margin-top: 56px;
  }
`;

const PostHeader = styled.div`
  padding: 60px 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e4e6e9;

  @media (max-width: 1200px) {
    padding: 60px 12.5%;
  }

  @media (max-width: 720px) {
    padding: 30px 20px;
  }
`;

const HeaderContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 38px;
  color: #222;

  @media (max-width: 720px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;

const SitemapWrapper = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`;

const PostIndex: React.FC<PostIndexProps> = ({ pageContext, location, data }) => {
  const { allProductsJson } = data;
  const { lang, tag, tags } = pageContext;
  const suffix = getSuffix(location.pathname);
  let posts = tag ? data.posts : data.allPosts;
  if (location.pathname.indexOf(postUrl.EN) !== -1) {
    posts = tag ? data.enPosts : data.allEnPosts;
  }
  const sitemapData = createProductSectionData(allProductsJson);

  const ga = createGA('Posts - sitemap', 'click', 'sitemap');

  return (
    <>
      <Contents>
        <SEO
          title="Posts"
          description="All about Front-End. Share the articles translated into Korean or written by NHN Cloud FE Development Lab."
        />
        <PostHeader>
          <HeaderContainer>
            <Title>Posts</Title>
            <PostTags tags={tags} currentTag={tag} lang={lang} isShowCnt={true} />
          </HeaderContainer>
        </PostHeader>
        <PostsList posts={posts} lang={lang} />
        <PostsPagination currentTag={tag} suffix={suffix} {...pageContext} />
      </Contents>
      <SitemapWrapper>
        <SitemapSection items={sitemapData} ga={ga} />
      </SitemapWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $tag: String) {
    ...allPosts
    ...allEnPosts
    ...posts
    ...enPosts

    # query for each section
    ...allProductsJson
  }
`;
export default PostIndex;
