import React from 'react';
import styled from 'styled-components';

import { IconType } from '@t/styles';
import { postUrl } from '@/constant';
import { InlineBlockLink } from '@/components/common/Link';
import { Icon } from '@/components/common/Icon';
import createGA from '@/components/common/createGA';

interface PaginationProps {
  currentTag?: string;
  lang: 'ko' | 'en';
  visiblePages: number[];
  suffix: string;
  previous: number | null;
  next: number | null;
  skipPrevVisible: number;
  skipNextVisible: number;
  numPages: number;
  tags: {
    [key: string]: number;
  };
}

type PageIconProps = {
  type: 'first' | 'prev' | 'next' | 'last';
} & PaginationProps;

type PageIconStatusInfoProps = {
  currentPrefix: string;
} & PaginationProps &
  PageIconProps;

const PostPagination = styled.div`
  margin: 0 auto;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > a,
  & > i {
    width: 34px;
    height: 34px;
    margin: 0 5px;
  }

  @media (max-width: 1200px) {
    & > a,
    & > i {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 720px) {
    margin: 25px auto;

    & > :first-child,
    & > :last-child {
      display: none !important;
    }
  }
`;

const PageArrowIcon = styled(Icon)`
  width: 34px;
  height: 34px;
  background-size: 1500px;

  &:hover {
    &.first.active {
      background-position: 83.2% 24.2%;
    }

    &.prev.active {
      background-position: 85.95% 24.2%;
    }

    &.next.active {
      background-position: 83.2% 28.9%;
    }

    &.last.active {
      background-position: 85.95% 28.9%;
    }
  }

  @media (max-width: 1200px) {
    width: 30px;
    height: 30px;
    background-size: 1400px;
  }

  @media (max-width: 720px) {
    background-size: 1200px;

    &.first,
    &.last {
      display: none;
    }

    &.prev {
      background-position: 60.8% 80.5%;
    }

    &:hover.prev.active,
    &.prev.active {
      background-position: 60.8% 89.1%;
    }

    &.next {
      background-position: 65.2% 89.1%;
    }

    &:hover.next.active,
    &.next.active {
      background-position: 65.2% 80.5%;
    }
  }
`;

const PageCountArea = styled.div`
  margin: 0 15px;

  & > span,
  & a {
    margin: 0 10px;
  }

  @media (max-width: 720px) {
    display: flex;
    justify-content: space-between;
    margin: 0 40px;

    & > span,
    & a {
      margin: 0;
    }
  }
`;

const PageCount = styled.span`
  font-size: 15px;
  color: #333;

  &:hover {
    color: #515ce6;
  }

  &.selected {
    font-weight: bold;
    color: #515ce6;
    border-bottom: 1px solid #515ce6;
  }

  @media (max-width: 720px) {
    font-size: 18px;
    margin: 0 17px;

    &.selected {
      border-bottom: none;
      margin: 0 17px;
    }
  }
`;

const getPrefixUrl = (lang: 'ko' | 'en', currentTag?: string) => ({
  prefix: postUrl[lang.toUpperCase() as 'KO' | 'EN'],
  prefixWithTag: `${postUrl[lang.toUpperCase() as 'KO' | 'EN']}/${currentTag}`,
});

const getIconStatusInfo = ({
  currentPrefix: url,
  type,
  previous,
  next,
  numPages,
  suffix,
}: PageIconStatusInfoProps) => {
  let isDisabled;

  switch (type) {
    case 'prev':
      url = previous === 1 ? url : `${url}/${previous}`;
      isDisabled = !previous;
      break;
    case 'next':
      url = `${url}/${next}`;
      isDisabled = !next;
      break;
    case 'first':
      isDisabled = isNaN(parseInt(suffix, 10)) || numPages === 1 || parseInt(suffix, 10) <= 5;
      break;
    case 'last':
      url = numPages === 1 ? url : `${url}/${numPages}`;
      isDisabled = numPages === 1 ? true : parseInt(suffix, 10) === numPages;
      break;
    default:
      break;
  }

  return {
    url,
    isDisabled,
  };
};

const PageIcon: React.FC<PageIconProps> = (props) => {
  const { currentTag, type, lang, skipPrevVisible, skipNextVisible } = props;
  const { prefix, prefixWithTag } = getPrefixUrl(lang, currentTag);

  const currentPrefix = currentTag ? prefixWithTag : prefix;
  const params = { ...props, currentPrefix };
  const { url, isDisabled } =
    type === 'prev' || type === 'next'
      ? getIconStatusInfo({ ...params, previous: skipPrevVisible, next: skipNextVisible })
      : getIconStatusInfo({ ...params });

  const ga = createGA('Posts - main', 'click', 'page icon', type);
  let iconType = type.toUpperCase();
  iconType = isDisabled ? `DISABLED_${iconType}_PAGE_ICON` : `${iconType}_PAGE_ICON`;

  return isDisabled ? (
    <PageArrowIcon iconType={iconType as IconType} className={type} data-testid={type} />
  ) : (
    <InlineBlockLink type="internal" url={url} testId={type} ga={ga}>
      <PageArrowIcon iconType={iconType as IconType} className={`${type} active`} />
    </InlineBlockLink>
  );
};

const PageNum: React.FC<PaginationProps> = ({ currentTag, visiblePages, lang, suffix }) => {
  const { prefix, prefixWithTag } = getPrefixUrl(lang, currentTag);
  const firstPageUrlSuffix = currentTag ? currentTag : lang;
  const firstPagePath = currentTag ? `${prefixWithTag}` : `${prefix}`;
  const ga = createGA('Posts - main', 'click', 'page count');

  return (
    <>
      {visiblePages.map((num) => {
        const pagePath = `${currentTag ? prefixWithTag : prefix}/${num}`;
        const isCurrentPageNum =
          parseInt(suffix, 10) === num || (num === 1 && suffix === firstPageUrlSuffix);

        return isCurrentPageNum ? (
          <PageCount key={num} className={'selected'} data-testid="current-page">
            {num}
          </PageCount>
        ) : (
          <InlineBlockLink
            key={num}
            type="internal"
            url={`${num === 1 ? firstPagePath : pagePath}`}
            testId="page-num"
            ga={{ ...ga, label: `page count - ${num}` }}
          >
            <PageCount>{num}</PageCount>
          </InlineBlockLink>
        );
      })}
    </>
  );
};

const PostsPagination: React.FC<PaginationProps> = (props) => (
  <PostPagination>
    <PageIcon {...props} type="first" />
    <PageIcon {...props} type="prev" />
    <PageCountArea>
      <PageNum {...props} />
    </PageCountArea>
    <PageIcon {...props} type="next" />
    <PageIcon {...props} type="last" />
  </PostPagination>
);

export default PostsPagination;
