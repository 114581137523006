import React from 'react';

import LazyImage from '@/components/detail/LazyImage';
import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface PostThumbnailProps {
  thumbnail: string;
  url: string;
}

const PostThumbnail: React.FC<PostThumbnailProps> = ({ thumbnail, url }) => {
  const ga = createGA('Posts - main', 'click', 'thumbnail');

  return (
    <InlineBlockLink type="internal" url={url} ga={ga}>
      <LazyImage src={thumbnail} />
    </InlineBlockLink>
  );
};

export default PostThumbnail;
